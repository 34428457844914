import * as React from "react";
import { NavLink } from 'react-router-dom';
import Styles from './Navbar.module.scss';

export default function Navbar() {
  function renderLink(href, label) {
    const currentPath = window.location.pathname === href;
    return <NavLink to={href} className={currentPath ? Styles.current : ''} >{label}</NavLink>
  }

  return (
    <nav className={Styles.navbar}>
      <ul>
        <li>
          {renderLink('/', 'Inicio')}
        </li>
        <li>
          {renderLink('/nuestro-gran-dia', 'Nuestro gran día')}
        </li>
        <li>
          {renderLink('/confirma-tu-asistencia', 'Confirma tu asistencia')}
        </li>
        <li>
          {renderLink('/contactanos', 'Contáctanos')}
        </li>
      </ul>
    </nav>
  )
}