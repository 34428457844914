import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { useForm } from '@formspree/react';

import Title from '../../components/Title';

import Styles from './index.module.scss';

export default function Confirm() {
  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORMSPREE_TOKEN);
  const [people, setPeople] = useState([]);

  useEffect(() => {
    if (people.length < 1) {
      addPerson()
    }
  })

  function addPerson() {
    setPeople([...people, {
      name: '',
      child: false
    }])
  }

  function removePerson(idx) {
    const ppl = [...people]
    ppl.splice(idx, 1);
    setPeople(ppl);
  }

  const updateFieldChanged = idx => e => {
    const ppl = [...people];
    if (e.target.name.match(/^fullname/)) {
      ppl[idx].name = e.target.value;
    }
    if (e.target.name.match(/^child/)) {
      ppl[idx].child = e.target.checked;
    }
    setPeople(ppl);
  }

  function renderPersonsInForm() {
    return people.map((_, idx) => (
      <div key={idx} className={Styles.formElement}>
        <div>
          <label htmlFor={"fullname" + idx}>Nombre y apellido(s) </label>
          <input type="text" name={"fullname" + idx} id={"fullname" + idx} required value={people[idx].name} onChange={updateFieldChanged(idx)} />
          <div>
            <label htmlFor={"child" + idx}>Infantil (14 años máx.) </label>
            <input type="checkbox" name={"child" + idx} id={"child" + idx} checked={people[idx].child} onChange={updateFieldChanged(idx)} />
          </div>
        </div>
        <div>
          <button 
            type="button" 
            className={[Styles.removePersonBtn, 'ligature'].join(' ')} 
            onClick={() => removePerson(idx)}
            disabled={idx === 0 && people.length <= 1} >
              Quitar
          </button>
        </div>
      </div>
    ))
  }

  function handleForm() {
    if (state.errors.length > 0) {
      console.error(state.errors);
      return (
        <div>
          <h2 className='ligature'>Ups...</h2>
          <p>Parece que hay un problemilla con el formulario de confirmación. Inténtalo de nuevo en un ratito, porfa.</p>
        </div>
      )
    }
    if (state.succeeded) {
      return (
        <div>
          <h2 className='ligature'>¡Confirmado!</h2>
          <p>Muchas gracias por confirmar tu asistencia. Significa muchísimo para nosotros, de verdad. ¡Nos vemos!</p>
        </div>
      )
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className={[Styles.formSection, Styles.people].join(' ')}>
          {renderPersonsInForm()}
          <button type="button" className={[Styles.addPersonBtn, 'ligature'].join(' ')} onClick={() => addPerson()}>
            Añadir persona
          </button>
        </div>
        <div className={[Styles.formSection, Styles.details].join(' ')}>
          <div className={Styles.detailsElement}>
            <label htmlFor="allergies">¿Alergias o manías? </label>
            <input type="text" name="allergies" id="allergies" required />
          </div>
          <div className={Styles.detailsElement}>
            <label htmlFor="phonenumber">Teléfono(s) de contacto </label>
            <input type="text" name="phonenumber" id="phonenumber" required />
          </div>
          <div className={Styles.detailsElement}>
            <label htmlFor="lodging">¿Necesitas alojamiento? </label>
            <input type="checkbox" name="lodging" id="lodging" />
          </div>
        </div>
        <div className='form-element-textarea'>
          <label htmlFor="comments">Notas y comentarios</label>
          <textarea rows="4" name="comments" id="comments" placeholder="Canciones que te molen, alguna dedicatoria, o algo más que debamos tener en cuenta." required></textarea>
        </div>
        <button type="submit" className={[Styles.confirmBtn, 'ligature'].join(' ')} disabled={state.submitting || state.succeeded}>
          Confirma
        </button>
      </form>
    )
  }

  return (
    <div className={Styles.Confirm}>
      <Title>Confirma tu asistencia</Title>
      <div className={Styles.content}>
        {handleForm()}
        <p>
          <span>Confirmaste tu invitación pero tienes cambios o quieres comentarnos algo?</span>&nbsp;
          <Link to="/contact">Envíanos un WhatsApp o llámanos.</Link></p>
      </div>
    </div>
  )
}
