import Title from '../../components/Title';

import Styles from './index.module.scss';

export default function Contact() {
  return (
    <div className={Styles.Contact}>
      <Title>Contáctanos</Title>
      <div className={Styles.content}>
        <div className={Styles.together}>
          <img src="/images/couple.jpeg" alt="Nosotros" />
          <div className={Styles.arrows}>
            <img className={Styles.arrowGroom} src="/images/arrow.svg" alt="Flecha" />
            <img className={Styles.arrowBride} src="/images/arrow.svg" alt="Flecha" />
          </div>
        </div>
        <div className={Styles.couple}>
          <div className={Styles.groom}>
            <p className="ligature">El novio</p>
            <p className="ligature">Arnau</p>
            <p className="ligature">
              <a target='_blank' rel='noreferrer' href={`https://wa.me/+34${process.env.REACT_APP_GROOM_NUMBER.replace(/\s/g, '')}`}>{process.env.REACT_APP_GROOM_NUMBER}</a>
            </p>
          </div>
          <div className={Styles.bride}>
            <p className="ligature">La novia</p>
            <p className="ligature">Judith</p>
            <p className="ligature">
              <a target='_blank' rel='noreferrer' href={`https://wa.me/+34${process.env.REACT_APP_BRIDE_NUMBER.replace(/\s/g, '')}`}>{process.env.REACT_APP_BRIDE_NUMBER}</a>
            </p>
          </div>
        </div>
        <div className={Styles.mysteryGift}>
          <p>Para nosotros lo más importante es contar con tu presencia. Pero si aun así nos quieres dar un detalle, ayúdanos a que nuestra luna de miel sea excepcional.</p>
          <h3>{process.env.REACT_APP_IBAN}</h3>
        </div>
      </div>
    </div>
  )
}
