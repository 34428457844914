import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import Home from "./pages/Home";
import Location from "./pages/Location";
import Navbar from "./components/Navbar";
import E404 from "./pages/404";
import Confirm from "./pages/Confirm";
import Contact from "./pages/Contact";

const RenderProperly = ({children}) => {
  return (
    <>
      <Navbar />
      {children}
    </>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RenderProperly><Home /></RenderProperly>
  }, {
    path: "nuestro-gran-dia",
    element: <RenderProperly><Location /></RenderProperly>
  }, {
    path: "confirma-tu-asistencia",
    element: <RenderProperly><Confirm /></RenderProperly>
  }, {
    path: "contactanos",
    element: <RenderProperly><Contact /></RenderProperly>
  }, {
    path: "*",
    element: <RenderProperly><E404 /></RenderProperly>
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
