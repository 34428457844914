import Moment from 'moment';
import "moment/locale/es";

import Title from '../../components/Title';

import Styles from './index.module.scss';

export default function Location() {
  return (
    <div className={Styles.Location}>
      <Title>Nuestro gran día</Title>
      <div className={Styles.content}>
        <div className={Styles.elem}>
          <div className={Styles.content}>
            <p>Hemos elegido el <strong>{process.env.REACT_APP_WEDDING_LOCATION_NAME}</strong> para celebrar la ceremonia y el banquete. Creemos que es un lugar mágico y que os encantará.</p>
            <p>El lugar se encuentra en <a target='_blank' rel='noreferrer' href={process.env.REACT_APP_WEDDING_LOCATION_COORDS}>{process.env.REACT_APP_WEDDING_LOCATION_ADDRESS}</a>.</p>
            <p>La fecha elegida es el <strong>{Moment(process.env.REACT_APP_WEDDING_DATE, 'YYYY-MM-DD').format('DD [de] MMMM [del] YYYY')}</strong>. Siempre hemos tenido veintiunos en nuestras vidas, y para un día tan importante no podía ser menos!</p>
          </div>
        </div>
        <div className={Styles.elem}>
          <div className={Styles.content}>
            <p>No olvides:</p>
            <ul>
              <li>Vestirte como quieras (pero un poco elegante, por favor),</li>
              <li>Las ganas de bailar (aunque bailes raro),</li>
              <li>Y reírte hasta de ti mism@.</li>
            </ul>
            <p className="ligature">Pero sobre todo...</p>
            <p>Llega sobre las {process.env.REACT_APP_WEDDING_TIME} 🙏</p>
          </div>
        </div>
      </div>
    </div>
  )
}
