import Moment from 'moment';

import Styles from './index.module.scss';

export default function Home() {
  const relationshipStartDate = Moment(process.env.REACT_APP_RELATIONSHIP_START_DATE, 'YYYY-MM-DD').format('YYYY');
  const now = Moment().format('YYYY');
  const yearsOfRelationship = now - relationshipStartDate;
  return (
    <div className={Styles.Home}>
      <div className={Styles.top}>
        <img src="/images/logo.png" alt="Logo" />
        <h2 className='ligature'>¡Bienvenid@s a nuestra boda!</h2>
      </div>
      <div className={Styles.intro}>
        <p>Y, sí... Después de {yearsOfRelationship} años de amor e ilimitadas aventuras nos hemos decidido...</p>
        <p className={[Styles.jumbo, "center", "bold"].join(' ')}>¡VAMOS A CASARNOS!</p>
        <p>Sí, sí, sí…. lo sabemos: "estáis locos", "eso ya no se lleva", "no seáis antiguos"…  ¿y qué? ¡A nosotros nos da igual romper el molde!</p>
        <p>Si estás viendo esto es porque <span className="underline">eres realmente especial para nosotros</span> y nada nos haría más felices que compartir contigo nuestro gran día.</p>
        <p>Mientras llega hemos creado esta web para que estés al día de todo (o no 😉).</p>
      </div>
      <div className={Styles.signature}>
        <p className="center">¡Te esperamos!</p>
        <p className="ligature right">~ Arnau y Judith</p>
      </div>
    </div>
  )
}
