import Styles from './Title.module.scss'

export default function Title({ children }) {
  return (
    <div className={Styles.title}>
      <img src="/images/heart_arrow.svg" alt="flecha a la derecha" />
      <h1 className="ligature">{children}</h1>
      <img src="/images/heart_arrow.svg" alt="flecha a la izquierda" />
    </div>
  )
}