import { Link } from 'react-router-dom';

import Title from '../../components/Title';

export default function E404() {
  return (
    <div>
      <Title>Página no encontrada</Title>
      <p className='center'>Parece que te has perdido. Te vamos a llevar a <Link to="/">un sitio seguro</Link></p>
    </div>
  )
}